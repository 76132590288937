import React from 'react';

import Typography from '../../_blocks/Typography/Typography';
import Icon from '../../_blocks/Icon/Icon';

import styles from './FlyoutHeader.module.css';

interface Props {
    title: string;
    closeIcon?: 'cross-light' | 'chevron-down-light';
    onClose: () => void;
}

const FlyoutHeader = ({ title, closeIcon = 'cross-light', onClose }: Props) => (
    <Typography
        as="div"
        typeset="subtitle"
        className={styles.header}
        size="200"
        lineHeight="200"
        aria-hidden="true"
        data-dd-header
    >
        {title}
        <button
            type="button"
            className="p-025 flex-shrink-0"
            onClick={onClose}
            title="Close menu"
        >
            <Icon
                className="box-content"
                icon={closeIcon}
                size="small"
                alt=""
            />
        </button>
    </Typography>
);

export default FlyoutHeader;
